import * as React from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CLocalNav,
  CHeroImg,
  CSectTitle,
  CHeroSliderMedia,
  CBtn,
  LSession,
  CBtnList,
  CPanelCard,
  CSliderMedia,
  LPlan,
} from '../../components/_index';
import { setLang } from '../../utils/set-lang';
import planChoice from '../../utils/plan-choice';
import planGet from '../../utils/plan-get';
import '../../assets/_sass/page/experience.scss';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const lang = setLang();

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        data={{
          title: {
            en: 'experience',
            ja: '過ごす',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/todo/kv.png',
              },
              imgSp: {
                src: '/assets/images/todo/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [],
            current: {
              label: '過ごす',
            },
          }}
        />
      </CJumbotron>
      <CLocalNav
        data={[
          {
            img: {
              src: '/assets/images/todo/img_localnav.png',
              alt: '過ごし方',
            },
            link: {
              href: '/experience#room',
              blank: false,
            },
            title: {
              ja: '過ごし方',
            },
          },
          {
            img: {
              src: '/assets/images/todo/img_localnav02.png',
              alt: 'アクティビティ',
            },
            link: {
              href: '/experience#activity',
              blank: false,
            },
            title: {
              ja: 'アクティビティ',
            },
          },
          {
            img: {
              src: '/assets/images/todo/img_localnav03.png',
              alt: 'エステ',
            },
            link: {
              href: '/experience#aesthetics',
              blank: false,
            },
            title: {
              ja: 'エステ',
            },
          },
        ]}
      />
      <div className="l_sect02">
        <LWrap>
          <section className="u_mbExLarge" id="room">
            <CSectTitle
              title={{ ja: <>過ごし方の一例</>, en: <>HOW TO STAY</> }}
            />
            <p className="c_sectLead u_mbMedium">
              仙台駅から車で約30分とは思えない心が落ち着く自然溢れる美しい景色とヨーロッパの趣を感じる当ホテル。
              <br />
              豊かな自然の中でゆったり過ごす贅沢なプランです。
            </p>
            <CHeroImg
              exClass="u_mbMedium"
              img={{
                src: '/assets/images/todo/img_todo.png',
                alt: '',
              }}
            />
            <CBtnList
              data={[
                {
                  label: '過ごし方の一例',
                  color: 'borderBlack',
                  link: {
                    href: '/experience/example/',
                  },
                },
              ]}
            />
          </section>
        </LWrap>
      </div>
      <div>
        <section id="activity">
          <LWrap>
            <CSectTitle
              title={{ ja: <>アクティビティ</>, en: <>ACTIVITY</> }}
            />
            <p className="c_sectLead u_mbMedium">
              日々の喧騒を忘れる杜の都のリゾートアクティビティ。
              <br />
              自然に囲まれた環境ならではの開放感溢れる体験を自由におたのしみいただけます。
            </p>
          </LWrap>
          {planChoice(planGet(), ['アクティビティ'], 0)
            .filter((item) => {
              return item.node.is_banner;
            })
            .map((item, index) => {
              return (
                <CPanelCard
                  key={index}
                  data={[
                    {
                      img: {
                        src: item.node.eyecatch.url,
                      },
                      title: <>{item.node.title}</>,
                      subTitle: <></>,
                      text: (
                        <>
                          {item.node.description &&
                            item.node.description
                              .split('\n')
                              .map((t: string) => (
                                <>
                                  {t}
                                  <br />
                                </>
                              ))}
                        </>
                      ),
                      btn: {
                        label: <>{item.node.title}</>,
                        link: {
                          href: `/experience/activity/${item.node.planSrphId}/`,
                        },
                      },
                    },
                  ]}
                  exClass={'u_mtSmall'}
                />
              );
            })}
        </section>
      </div>

      <div className="p_experienceTop">
        <LSession exClass="in_session_wrap" />
      </div>

      <section className="l_sect" id="aesthetics">
        <LWrap>
          <CSectTitle
            exClass="u_mb10"
            title={{
              ja: <>エステ</>,
              en: <>AESTHETICS</>,
            }}
          />
          <CSliderMedia
            data={[
              {
                img: [
                  {
                    img: {
                      src: '/assets/images/home/img_panel08.png',
                      alt: 'エステ',
                    },
                  },
                ],
                title: (
                  <>
                    癒やしの先の健康美リゾートで
                    <br />
                    満喫するちょっと贅沢なエステ
                  </>
                ),
                text: (
                  <>
                    アーユソーマでは、体調や体質に合わせた自然のオイルなどを使いながら、心身を健康に導いていく「アーユルヴェーダ」の考え方に基づいたプログラムをご提供します。
                  </>
                ),
                btn: {
                  link: {
                    href: '/aesthetic/',
                  },
                  label: 'エステ',
                },
              },
            ]}
          />
        </LWrap>
      </section>
      <LPlan />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
